<template>
  <div class="boothReserveMobile">
    <div class="areaDomContent">
      <div class="areaDom" id="areaDom">
        <boothMap :selected="selectBooth" :alreadySelect="alreadySelect" />
      </div>
    </div>
    <div class="headerColor">
      <div class="colorAndText">
        <div class="color red fl"></div>
        <div class="text fl">已售展台</div>
      </div>
      <div class="colorAndText">
        <div class="color green fl"></div>
        <div class="text fl">我的展台</div>
      </div>
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="70px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="联系人" required>
          <el-input v-model="ruleForm.username" :clearable="false" />
        </el-form-item>
        <el-form-item label="职位">
          <el-input v-model="ruleForm.position" :clearable="false" />
        </el-form-item>
        <el-form-item label="公司" required>
          <el-input v-model="ruleForm.company" :clearable="false" />
        </el-form-item>
        <el-form-item label="手机" required>
          <el-input v-model="ruleForm.mobile" :clearable="false" />
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="ruleForm.email" :clearable="false" />
        </el-form-item>
        <el-form-item label="展位" required>
          <div class="booth">
            <div class="addBooth" @click="addBooth">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/expoQD2023/icon/addBooth.png"
                alt=""
              />
              添加展位
            </div>
            <div class="select">
              <div
                class="select-item"
                v-for="(item, index) in selectBooth"
                :key="index"
              >
                <span>
                  {{ item.booth_no }}({{ item.hbh_booth_type_name }}
                  {{ item.price_cny||('$'+item.price_usd) }}元/展位 )
                </span>
                <img
                  @click="deletedSomeBooth(index)"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/expoQD2023/icon/delBooth.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="control-btn">
      <span>
        共{{ selectBooth.length }}项 合计：<span class="red"
          >¥{{ total_fee }}<span v-if="usdTotal" style="margin-left:10px;">{{'$'+usdTotal}}</span></span
        >
      </span>
      <el-button
        :loading="loading"
        size="small"
        :disabled="!selectBooth.length"
        @click="submit"
        :type="selectBooth.length ? 'primary' : ''"
      >
        提交预定
      </el-button>
    </div>
    <chooseBoth ref="chooseBoth" @checkBack="checkBack" />
  </div>
</template>

<script>
import boothMap from "~exp/components/booth/boothMap";
import chooseBoth from "~exp/components/booth/chooseBoth";
import { validateTelephone, validateEmail } from "~/baseUtils/validate";

export default {
  name: "boothMobile",
  metaInfo: {
    title: "预定展位",
  },
  components: {
    boothMap,
    chooseBoth,
  },
  data() {
    return {
      isBooked: false,
      user_id: 53111,
      selectBooth: [],
      alreadySelect: [],
      ruleForm: {
        username: "",
        position: "",
        company: "",
        mobile: "",
        email: "",
        book_id: "",
      },
      bookedMsg: {},
      total_fee: 0, //总费用
      usdTotal:0,
      booth_ids: "",
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
    this.initAllInfo();
  },
  methods: {
    async initAllInfo() {
      await this.getUserInfo();
      this.initPinchzoom();
    },
    async getUserInfo() {
      // 给订单赋值的接口
      let resBook = await this.$store.dispatch("booth/getBoothBook", {
        user_id: this.user_id,
        meeting_id:71
      });
      if (resBook.data.length) {
        // 预定过了
        this.isBooked = true;
        let msg = resBook.data[0];
        this.ruleForm.username = msg.username;
        this.ruleForm.position = msg.position;
        this.ruleForm.company = msg.company;
        this.ruleForm.mobile = msg.mobile;
        this.ruleForm.email = msg.email;
        this.ruleForm.book_id = msg.id;
        this.bookedMsg = msg;
        this.bookedMsg.total_fee=JSON.parse(this.bookedMsg.total_fee)
      } else {
        let res = await this.$store.dispatch("register/getboothUserInfo", {
          user_id: this.user_id,
        });
        if (res.success) {
          let info = res.data;
          this.ruleForm.username = info.name_zh || info.name_en;
          this.ruleForm.position = info.job_title_zh || info.job_title_en;
          this.ruleForm.company = info.company_name_zh || info.company_name_en;
          this.ruleForm.mobile = info.mobile || "";
          this.ruleForm.email = info.email || "";
        }
      }
    },
    initPinchzoom() {
      this.$nextTick(() => {
        $("div.areaDom").each(function() {
          new RTP.PinchZoom($(this), {});
        });
      });
    },
    addBooth() {
      this.$refs.chooseBoth.openInit(this.selectBooth);
    },
    deletedSomeBooth(index) {
      this.selectBooth.splice(index, 1);
      this.computedFree();
    },
    checkBack(e) {
      this.selectBooth = JSON.parse(JSON.stringify(e.check_arr));
      this.computedFree();
    },
    // 计算费用
    computedFree() {
      let ids = [];
      let total_fee = 0; //总费用
      let usdTotal=0//美元费用
      this.selectBooth.forEach((item, i) => {
        ids.push(item.id);
        if (item.price_cny) {
          total_fee += item.price_cny * 1;
        } else {
          usdTotal+=item.price_usd * 1;
        }
      });
      this.total_fee = total_fee;
      this.usdTotal=usdTotal
      this.booth_ids = ids.join(",");
    },
    async submit() {
      if (this.loading || !this.selectBooth.length) {
        return false;
      }
      this.loading = true;
      let rules = [
        { key: "username", msg: "请输联系人" },
        { key: "company", msg: "请输入公司" },
        { key: "mobile", msg: "请输入手机号" },
      ];
      for (let index = 0; index < rules.length; index++) {
        if (!this.ruleForm[rules[index].key]) {
          this.$notify({
            title: "提示",
            message: rules[index].msg,
            type: "warning",
          });
          this.loading = false;
          return;
        }
        if (
          rules[index].key == "mobile" &&
          !validateTelephone(this.ruleForm.mobile)
        ) {
          this.$notify({
            title: "提示",
            message: "手机号格式不对",
            type: "warning",
          });
          this.loading = false;
          return;
        }
      }
      if (this.ruleForm.email && !validateEmail(this.ruleForm.email)) {
        this.$notify({
          title: "提示",
          message: "邮箱格式不对",
          type: "warning",
        });
        this.loading = false;
        return;
      }

      let params = {
        created_from: 1, // '创建来源：1-前台，2-后台',
        user_id: this.user_id,
        username: this.ruleForm.username,
        position: this.ruleForm.position,
        company: this.ruleForm.company,
        mobile: this.ruleForm.mobile,
        email: this.ruleForm.email,
        booth_ids: this.booth_ids,
        total_fee: {
          "CNY": this.total_fee,
          "USD":this.usdTotal
        }, //总费用
        _NOCLEAR: true,
      };
      let res = null;
      if (this.isBooked) {
        params.book_id = this.ruleForm.book_id;
        params.total_fee.CNY = params.total_fee.CNY + this.bookedMsg.total_fee.CNY;
        params.total_fee.USD = params.total_fee.USD + this.bookedMsg.total_fee.USD;
        delete params.user_id;
        params.total_fee=JSON.stringify(params.total_fee)
        res = await this.$store.dispatch("register/reBook", params);
      } else {
        params.meeting_id = 71
        params.total_fee=JSON.stringify(params.total_fee)
        res = await this.$store.dispatch("register/reserve_booth", params);
      }
      if (res.success) {
        this.loading = false;
        // 提交成功
        wx.miniProgram.redirectTo({
          url: "/overtPackage/pages/bookInfo/index",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.red {
  color: #ff0000;
}

.boothReserveMobile {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding-bottom: 100px;

  .areaDomContent {
    padding: 0 15px;
  }

  .headerColor {
    width: 100%;
    height: 50px;
    background-color: #fefefe;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px;

    .colorAndText {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 14px;
    }

    .color {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      text-align: center;
      line-height: 18px;
      font-size: 18px;
      font-weight: bold;

      &.red {
        background: #7a7a7a;
      }

      &.green {
        background: #ff5a5e;
      }
    }
  }

  .form {
    background-color: #fff;
    padding: 0 15px;
    margin-top: 10px;

    /deep/ .el-form-item__content {
      line-height: 30px;
    }

    /deep/ .el-form-item {
      border-bottom: 1px solid #ebedf0;
      margin-bottom: 5px;

      &:last-child {
        border-bottom: none;
      }
    }

    /deep/ .el-input__inner {
      border-color: transparent;
      text-align: right;
      padding-right: 0;
    }

    .booth {
      .addBooth {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        color: #c8c9cc;
        //padding-right: 15px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }

      .select {
        min-height: 50px;
        margin-top: 20px;

        .select-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          background: #f0f5ff;
          border-radius: 10px;
          margin-bottom: 10px;
          color: #4f88f7;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .control-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    color: #000;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    height: calc(50px + constant(safe-area-inset-bottom));
    height: calc(50px + env(safe-area-inset-bottom));
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
  }
}
</style>
